import { createMuiTheme } from "@material-ui/core/styles"

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#181717",
    },
    error: {
      main: "#FE5000",
    },
  },
  typography: {
    fontFamily: ["Graphik LG Web", "Arial", "sans-serif"].join(","),
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: "1rem",
        "@media (min-width: 768px)": {
          fontSize: "1.25rem",
        },
        "@media (min-width: 1200px)": {
          fontSize: "1.5rem",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        margin: 0,
        textAlign: "right",
        position: "absolute",
        bottom: "9px",
        right: "32px",
      },
    },
    MuiFormControlLabel: {
      label: {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: "translate(0, 1.5em) scale(1)",
      },
      shrink: {
        transform: "translate(0, 1.5px) scale(.5)",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1.25rem",
        "@media (min-width: 768px)": {
          fontSize: "1.5rem",
        },
        "@media (min-width: 1200px)": {
          fontSize: "2rem",
        },
      },
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
    },
    MuiInput: {
      root: {
        "&$underline": {
          "&:hover:not(.Mui-disabled)": {
            "&:before": {
              borderBottom: "2px solid rgba(0, 0, 0, 0.42)",
            },
          },
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      },
    },

    MuiAccordion: {
      root: {
        "&$expanded": {
          margin: 0,
          "&:before": {
            opacity: "1",
          },
        },
        "&:before": {
          display: "none",
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        "&$expanded": {
          margin: 0,
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
        border: "1px solid #CCC8C3",
        borderTop: "0",
        "&.first": {
          borderTop: "1px solid #CCC8C3",
        },
      },
    },
  },
})

export { theme }
