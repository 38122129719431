import PropTypes from "prop-types"
import React, { useState, useRef, useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import { LinkAnim } from "./link"
import Menu from "./menu"
import { gsap, Elastic } from "gsap"

let Header = ({ siteTitle }) => {
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const timeline = useRef(gsap.timeline({ paused: true }))
  const spanRefs = useRef([])

  useEffect(() => {
    timeline.current.to(spanRefs.current, {
      y: "-=100%",
      ease: Elastic.easeOut.config(1, 0.4),
      duration: 1,
    })

    const tm = timeline.current
    return () => {
      tm.kill()
    }
  }, [])

  useEffect(() => {
    menuOpen ? timeline.current.play() : timeline.current.reverse()
  }, [menuOpen])

  return (
    <header id="header" className="sp-pt-2 sp-pb-2 mui-fixed">
      <LinkAnim className="aka-logo header-btn" to="/">
        Highloft
      </LinkAnim>
      <LinkAnim className="sp-mr-5 header-btn" to="/book/">
        {t("book")}
      </LinkAnim>
      <button
        onClick={() => setMenuOpen(menuOpen => !menuOpen)}
        className="header-btn"
      >
        <span
          ref={ref =>
            ref && !spanRefs.current.includes(ref) && spanRefs.current.push(ref)
          }
        >
          Menu
        </span>
        <span
          ref={ref =>
            ref && !spanRefs.current.includes(ref) && spanRefs.current.push(ref)
          }
          role="presentation"
        >
          Close
        </span>
      </button>
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
