import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import { LinkAnim } from "./link"
import Logo from "../static/logo-black.svg"
//
import Icon from "@mdi/react"
import { mdiArrowCollapseDown } from "@mdi/js"
import { mdiEmailOutline } from "@mdi/js"
import { mdiEmailOpenOutline } from "@mdi/js"
import { mdiArrowDown } from "@mdi/js"
import CookieConsentContext from "../context/CookieContext"

import Styles from "../styles/modules/footer.module.scss"
import Obfuscate from 'react-obfuscate';

const Footer = props => {
  const { t, i18n } = useTranslation(["translation", "footer"])

  let { pdfs } = useStaticQuery(graphql`
    query PdfQuery {
      pdfs: allFile(filter: { ext: { eq: ".pdf" } }) {
        edges {
          node {
            size
            name
            relativePath
          }
        }
      }
    }
  `)

  const pdf = pdfs.edges
    .filter(o => {
      return o.node.name === "presentation_" + i18n.language
    })
    .pop()

  return (
    <footer className={`${Styles.footer} text--sm`}>
      <div className="container-fluid">
        <div className="row">
          <div className={`${Styles.contact} col`}>
            <ul>
              <li>
                <a
                  href="https://goo.gl/maps/WpbqVuf4H6RLN77Y9"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t("footer:address_1")}
                  <br />
                  {t("footer:address_2")}
                </a>
              </li>
              <li>
                <a href="tel:+302103425019">+30 210 34 25 019</a>
              </li>
            </ul>
          </div>
          <div className={`${Styles.social} col`}>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/thehighloft"
                  rel="noreferrer"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/thehighloft"
                  rel="noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://tr.linkedin.com/company/thehighloft"
                  rel="noreferrer"
                  target="_blank"
                >
                  Linkedin
                </a>
              </li>
            </ul>
          </div>
          <div className={`${Styles.links} col`}>
            <ul>
              <li>
                <LinkAnim to="/legal/privacy-policy" rel="nofollow">
                  {t("footer:privacy_policy")}
                </LinkAnim>
              </li>
              <li>
                <LinkAnim to="/legal/cookies-policy" rel="nofollow">
                  {t("footer:cookies_policy")}
                </LinkAnim>
              </li>
              <CookieConsentContext.Consumer>
                {({ setPopupIsOpen }) => (
                  <li>
                    <span
                      onClick={() => setPopupIsOpen(true)}
                      style={{ cursor: "pointer" }}
                    >
                      {t("footer:cookies_settings")}
                    </span>
                  </li>
                )}
              </CookieConsentContext.Consumer>
              {/*<li><LinkAnim to="/legal/terms-of-use" rel="nofollow">{t("footer:terms_of_use")}</LinkAnim></li>*/}
            </ul>
          </div>
          <div className={`${Styles.pdf} col`}>
            {(() =>
              pdf ? (
                <>
                  <a
                    href={`/${pdf.node.name}.pdf`}
                    aria-label="download"
                    download
                  >
                    {t("footer:Download pdf presentation")}
                  </a>
                  <br />
                  <span className="color--gray1">
                    {(pdf.node.size * Math.pow(10, -6)).toFixed(1)}mb
                  </span>
                  <br />
                  <a
                    aria-label="download"
                    className={`${Styles.footerBtn} sp-mt-2`}
                    href={`/${pdf.node.name}.pdf`}
                    download
                  >
                    <Icon
                      path={mdiArrowDown}
                      size={0.666}
                      className={Styles.alternative}
                    />
                    <Icon path={mdiArrowCollapseDown} size={0.666} />
                    <span className={Styles.hiddenBtnText}>
                      {t("downloadNow")}
                    </span>
                  </a>
                </>
              ) : (
                <></>
              ))()}
          </div>
          <div className={`${Styles.email} col`}>
            {/*<a href="mailto:hello@thehighloft.com">hello@thehighloft.com</a>*/}
            <Obfuscate
              email="hello@thehighloft.com"
            />
            <br />
            <span className="color--gray1">{t("footer:get_in_touch")}</span>
            <br />

            <Obfuscate
              email="hello@thehighloft.com"
              obfuscateChildren={false}
              className={`${Styles.footerBtn} sp-mt-2`}
            >
              <Icon
                className={Styles.alternative}
                path={mdiEmailOpenOutline}
                size={0.666}
              />
              <Icon path={mdiEmailOutline} size={0.666} />
              <span className={Styles.hiddenBtnText}>{t("emailUs")}</span>
            </Obfuscate>
          </div>
        </div>
        <div className="row sp-mt-9 sp-pb-2 align-items-end color--gray1">
          <div className="col-12 col-sm-7 col-xxl-5">
            <Logo />
          </div>
          <div className="col-12 col-lg-6 col-xxl-3 offset-xxl-2">
            Designed and developed by{" "}
            <a href="https://souldesign.gr" target="_blank" rel="noreferrer">
              soul
            </a>
          </div>
          <div className="col-12 col-lg-6 col-xxl-2">©The Highloft 2019</div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
