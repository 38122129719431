import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography"
import { ThemeProvider } from "@material-ui/core/styles"
import { theme } from "../components/md-ui-theme"
import Styles from "../styles/modules/cookie.module.scss"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"

const CookiePopup = ({ value, dispatch }) => {
  const { t } = useTranslation("cookies")

  return (
    <div className={Styles.cookiesOverlay}>
      <div className={`${Styles.cookiesPopup} text--sm color--black`}>
        <CookiePopUpContent value={value} dispatch={dispatch} />
        <div className="sp-mt-6">
          <button
            className="button button--light text--sm sp-mr-2 sp-mb-2"
            onClick={() => dispatch({ type: "acceptCurrent" })}
          >
            {t("save")}
          </button>
          <button
            className="button button--light text--sm sp-mr-2 sp-mb-2"
            onClick={() => dispatch({ type: "declineAll" })}
          >
            {t("decline_all")}
          </button>
          <button
            className="button button--light text--sm sp-mb-2"
            onClick={() => dispatch({ type: "acceptAll" })}
          >
            {t("accept_all")}
          </button>
        </div>
      </div>
    </div>
  )
}

const CookiePopUpContent = ({ value, dispatch }) => {
  const { t } = useTranslation("cookies")

  return (
    <>
      <h1 className="text--sm">{t("title")}</h1>
      <p>{t("content")}</p>
      <ThemeProvider theme={theme}>
        <Accordion className="sp-mt-4 first">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <FormControlLabel
              aria-label={t("nessesary_name")}
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={
                <Checkbox checked={true} disabled={true} name="nessesary" />
              }
              label={t("nessesary_name")}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t("nessesary_desc")}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <FormControlLabel
              aria-label={t("performance_name")}
              onClick={event => event.stopPropagation()}
              onFocus={event => event.stopPropagation()}
              control={
                <Checkbox
                  checked={value.performance}
                  name="performance"
                  color="default"
                  onChange={() => dispatch({ type: "performance" })}
                />
              }
              label={t("performance_name")}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t("performance_desc")}</Typography>
          </AccordionDetails>
        </Accordion>

        {/*<Accordion>*/}
        {/*  <AccordionSummary*/}
        {/*    expandIcon={<ExpandMoreIcon />}*/}
        {/*    aria-label="Expand"*/}
        {/*    aria-controls="additional-actions1-content"*/}
        {/*    id="additional-actions1-header"*/}
        {/*  >*/}
        {/*    <FormControlLabel*/}
        {/*      aria-label={t("marketing_name")}*/}
        {/*      onClick={event => event.stopPropagation()}*/}
        {/*      onFocus={event => event.stopPropagation()}*/}
        {/*      control={*/}
        {/*        <Checkbox*/}
        {/*          checked={value.marketing}*/}
        {/*          name="marketing"*/}
        {/*          color="default"*/}
        {/*          onChange={() => dispatch({ type: "marketing" })}*/}
        {/*        />*/}
        {/*      }*/}
        {/*      label={t("marketing_name")}*/}
        {/*    />*/}
        {/*  </AccordionSummary>*/}
        {/*  <AccordionDetails>*/}
        {/*    <Typography>{t("marketing_desc")}</Typography>*/}
        {/*  </AccordionDetails>*/}
        {/*</Accordion>*/}
      </ThemeProvider>
    </>
  )
}
export { CookiePopUpContent }
export default CookiePopup
