import { useEffect, useState, useCallback } from "react"
import { useIsClient } from "./useIsClient"

export const useWindowSize = () => {
  const isClient = useIsClient()

  const getSize = useCallback(
    () => ({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }),
    [isClient]
  )

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    const handleResize = () => {
      if (isClient) {
        setWindowSize(getSize())
      }
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [getSize])

  return windowSize
}
