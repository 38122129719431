import React, { useContext } from "react"

import { I18nextContext } from "gatsby-plugin-react-i18next-nocookie"

import gsap from "gsap"
import TransitionLink from "gatsby-plugin-transition-link"

export const LinkAnim = props => {
  const language = props.language,
    to = props.to

  const _onClick = props.onClick

  const context = useContext(I18nextContext)
  const urlLanguage = language || context.language
  const link =
    urlLanguage !== context.defaultLanguage ? "/" + urlLanguage + to : to

  const exitAnim = ({ exit, entry, node, e }) => {
    const { length } = exit

    return gsap
      .timeline()
      .set("#TransitionPortal", { y: 0 })
      .set("#TransitionPortal .item", { y: "-100%" })
      .set("#TransitionPortal .item__inner", { x: "99.5%" })

      .to("#TransitionPortal .item", {
        y: 0,
        duration: length / 2,
      })
      .to("#TransitionPortal .item__inner", {
        x: 0,
        duration: length / 2,
      })
      .set("#TransitionPortal", { y: "-100%" })
  }

  const enterAnim = ({ exit, entry, node, e }) => {
    const { length } = entry

    return gsap
      .timeline()
      .set("#TransitionPortal", { y: 0 })
      .set("#TransitionPortal .item", { y: 0 })
      .set("#TransitionPortal .item__inner", { x: 0 })
      .to("#TransitionPortal .item__inner", {
        x: "-100%",
        duration: length,
      })
      .set("#TransitionPortal", { y: "-100%" })
  }

  return (
    <TransitionLink
      to={link}
      hrefLang={urlLanguage}
      className={props.className}
      onClick={e => {
        if (_onClick) {
          _onClick(e)
        }
      }}
      exit={{
        length: 1,
        trigger: exitAnim,
      }}
      entry={{
        delay: 0.95,
        length: 1,
        trigger: enterAnim,
      }}
    >
      {props.children}
    </TransitionLink>
  )
}
