import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import CookieConsentContext from "../context/CookieContext"
import CookiePopup from "./cookiePopup"
import Header from "./header"
import Footer from "./footer"
import "../fonts/fonts.css"
import "../styles/main.scss"
import { TransitionPortal } from "gatsby-plugin-transition-link"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <CookieConsentContext.Consumer>
      {({ state, dispatch, popupIsOpen }) => (
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>
          <Footer />
          <div>
            {popupIsOpen && <CookiePopup dispatch={dispatch} value={state} />}
          </div>
          <TransitionPortal>
            <div id="TransitionPortal">
              {[0, 1, 2, 3, 4].map((value, index) => {
                return (
                  <div key={index} className="item">
                    <div className="item__inner"></div>
                  </div>
                )
              })}
            </div>
          </TransitionPortal>
        </>
      )}
    </CookieConsentContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
