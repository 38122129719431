import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import { useStaticQuery, graphql } from "gatsby"
import { CustomEase } from "gsap/CustomEase"
import { useWindowSize } from "../hooks/windowsize"
import Img from "gatsby-image"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next-nocookie"

import { LinkAnim } from "./link"

gsap.registerPlugin(CustomEase)

/**
 * Website main navigation
 * @trans translation key
 * @link  internal page link
 * @type {Array}
 */
export const mainmenu = [
  {
    trans: "theLoft",
    link: "/the-loft",
  },
  {
    trans: "hostAnEvent",
    link: "/events",
  },
  {
    trans: "photoshoots",
    link: "/photoshoots",
  },
  {
    trans: "gallery",
    link: "/gallery",
  },
  {
    trans: "bookNow",
    link: "/book",
  },
  {
    trans: "faq",
    link: "/faq",
  },
  {
    trans: "reviews",
    link: "/reviews",
  },
]

export default ({ menuOpen, setMenuOpen }) => {
  // Get items' image
  const { menuImages } = useStaticQuery(graphql`
    query {
      menuImages: allFile(
        filter: { relativePath: { regex: "/menu/" } }
        sort: { order: ASC, fields: absolutePath }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  // Hanle if client is touch
  const isTouch = useRef(false)

  // translations
  const { languages, originalPath } = useI18next()
  const { t } = useTranslation()

  // handle window dimensions {width, height}
  const size = useWindowSize()

  // handle mouseover delay
  const HOVER_DELAY = 300 // Hover delay in milliseconds
  const timeout = useRef(null)

  // Menu timeline
  const tl = useRef(null)

  const menuEase = CustomEase.create("cubic", ".42,0,.58,1")

  const linkRefs = useRef([])
  const imgRefs = useRef([])

  /**
   * [addToRefsArray description]
   * @param {React.ref} el       the ref to add
   * @param {React.ref} refArray the ref array the element will be added
   */
  const addToRefsArray = (el, refArray) => {
    if (el && !refArray.current.includes(el)) {
      refArray.current.push(el)
    }
  }

  let zIndex = 0

  const handleMouseEnter = index => {
    timeout.current = setTimeout(() => {
      gsap.to(
        linkRefs.current.filter((v, i) => index !== i),
        {
          y: "40%",
          color: "#8d8888",
          duration: 0.3,
        }
      )

      gsap.set(imgRefs.current[index], { zIndex: ++zIndex })

      !isTouch.current &&
        gsap.fromTo(
          imgRefs.current[index],
          {
            y: "100%",
            duration: 0.8,
            ease: menuEase,
          },
          {
            y: 0,
          }
        )
    }, HOVER_DELAY)
  }

  const handleMouseLeave = index => {
    clearTimeout(timeout.current)

    gsap.to(
      linkRefs.current.filter((v, i) => index !== i),
      {
        y: "0%",
        color: "#181717",
        duration: 0.3,
      }
    )

    gsap.set(imgRefs.current[index], { zIndex: zIndex - 1 })
    !isTouch.current &&
      gsap.to(imgRefs.current[index], {
        duration: .8,
        delay: 0.8 + HOVER_DELAY / 1000,
        y: "100%",
        ease: menuEase,
        overwrite: "auto"
      })
  }

  useEffect(() => {
    if ("ontouchstart" in window) {
      isTouch.current = true
    }

    tl.current = gsap.timeline({ paused: true })
    tl.current.to(
      ".mainMenu",
      0.6,
      {
        y: 0,
        ease: menuEase,
      },
      0
    )

    tl.current.to(
      ".mainMenu__link__num, .mainMenu__link__text",
      0.4,
      {
        y: 0,
        ease: menuEase,
        stagger: 0.075,
      },
      0
    )

    tl.current.to(
      ".mainMenu__link__line",
      0.8,
      {
        width: "100%",
        ease: menuEase,
        stagger: 0.025,
      },
      0.1
    )

    return () => {
      tl.current.kill()
    }
  }, [])

  useEffect(() => {
    gsap.set(".mainMenu", { y: "-100%" })
    setMenuOpen(false)
  }, [setMenuOpen, size.height])

  useEffect(() => {
    menuOpen ? tl.current.play() : tl.current.reverse()
  }, [menuOpen])

  return (
    <div className="mainMenu">
      <div className="mainMenu__links__wrapper sp-pt-7">
        <div className="menu__H">
          <img src="/H.svg" alt="The Highloft" />
        </div>
        <ul className="mainMenu__links" role="navigation">
          {mainmenu.map((v, i) => (
            <li className="mainMenu__link" key={i}>
              <div
                className="mainMenu__link__inner"
                ref={ref => addToRefsArray(ref, linkRefs)}
              >
                <span className="mainMenu__link__num">
                  {("0" + (i + 1)).slice(-2)}
                </span>
                <span
                  className="mainMenu__link__text"
                  role="menuitem"
                  tabIndex={-1}
                  onFocus={e => handleMouseEnter(i)}
                  onBlur={e => handleMouseLeave(i)}
                  onMouseEnter={e => handleMouseEnter(i)}
                  onMouseLeave={e => handleMouseLeave(i)}
                  onClick={e => {
                    originalPath === v.link + "/" && setMenuOpen(false)
                  }}
                >
                  <LinkAnim to={v.link}>{t(v.trans)}</LinkAnim>
                </span>
              </div>
              <span className="mainMenu__link__line"></span>
            </li>
          ))}
          <li className="mainMenu__link">
            <div
              className="mainMenu__link__inner"
              ref={ref => addToRefsArray(ref, linkRefs)}
            >
              <span className="mainMenu__link__num">
                {("0" + (mainmenu.length + 1)).slice(-2)}
              </span>
              <span className="mainMenu__link__text">
                {languages.map(lng => (
                  <LinkAnim
                    to={originalPath}
                    key={lng}
                    className="lang"
                    language={lng}
                  >
                    {lng.charAt(0).toUpperCase() + lng.slice(1)}
                  </LinkAnim>
                ))}
              </span>
            </div>
            <span className="mainMenu__link__line"></span>
          </li>
        </ul>
        <div className="mainMenu__images">
          <div className="mainMenu__images__inner">
            {menuImages.nodes.map((v, i) => (
              <div key={i} ref={ref => addToRefsArray(ref, imgRefs)}>
                <Img fluid={v.childImageSharp.fluid} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
